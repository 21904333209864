@use '/styles/mixin/fonts.module.scss' as *;
.drive-review__top-cars {
  &__wrapper {
    @apply w-full;
    @apply w-320px;
    @apply mb-16;
    @screen lg {
      @apply w-298px;
    }
  }
  &__header {
    @apply flex;
    @apply mt-0;
    @apply mb-6;
    @include font-subtext('desktop', 'medium');
  }
  &__header-icon-wrapper {
    @apply w-6;
    @apply h-6;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply bg-black;
    @apply rounded-full;
    @apply mr-4;
    @apply p-1.5;
  }
  &__header-icon {
    @apply text-lg;
    @apply fill-current;
    @apply text-white;
  }
  &__card {
    @apply flex;
    @apply h-96px;
    @apply mb-2;
    @apply border;
    @apply border-gray-200;
  }
  &__card-wrapper {
    @apply flex;
    @apply w-140px;
    @apply shrink-0;
    @apply relative;
    @apply justify-center;
    @apply items-center;
    @apply bg-teal-dark;
  }
  &__card-count {
    @apply flex;
    @apply bg-gray-200;
    @apply rounded-full;
    @apply w-6;
    @apply h-6;
    @apply justify-center;
    @apply items-center;
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply m-2;
    @apply text-13px;
    @apply leading-4;
  }
  &__card-thumbnail {
    @apply w-140px;
    @apply h-96px;
    @apply inline-flex;
  }
  &__card-image-wrapper {
    @apply flex;
    @apply items-center;
    @apply w-8;
    @apply h-full;
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply -mr-4;
  }
  &__card-image-figure {
    @apply border;
    @apply w-8;
    @apply h-8;
    @apply rounded-full;
  }
  &__card-image {
    @apply object-cover;
    @apply w-full;
    @apply h-full;
    @apply rounded-full;
  }

  &__link-wrapper {
    @apply flex;
    @apply items-center;
  }
  &__title {
    @include font-subtext('desktop', 'medium');
    @apply my-0;
    @apply ml-6;
    @apply text-black;
  }
}
